import React from 'react'


function Banquettes() {
    return (
        <>
        </>
    )
}

export default Banquettes